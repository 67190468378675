import { Typography, Link, Box } from "@mui/material";
import DragAndDropComponent from "../components/DragAndDropComponent";
import { Link as RouterLink } from "react-router-dom";
import { useTitle } from "../store/AppTitleContext";
import { useEffect } from "react";

export default function Uploader() {
    const { updateTitle } = useTitle();

    useEffect(() => {
        updateTitle("File Uploader");
    }, [updateTitle]);
    return (
        <>
            <Box margin={2}>
                <Typography>
                    Markdownの編集は
                    <Link
                        component={RouterLink}
                        to="/editor"
                        target="_blank"
                        rel="noopener noreferrer">
                        こちら
                    </Link>
                    からどうぞ。
                </Typography>
                <Typography>
                    不適切なアップロードを防ぐため、アップロードにはキーが必要です。
                    <br />
                    お持ちでない場合、管理者にお問い合わせください。
                </Typography>
            </Box>

            <DragAndDropComponent />
        </>
    );
}
