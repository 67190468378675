import { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { apiInstance } from "../ApiInstance";
import { useTitle } from "../store/AppTitleContext";
import {
    Backdrop,
    Button,
    CircularProgress,
    Grid,
    Link,
    Typography,
} from "@mui/material";
import "./MdViewer.css";
import React from "react";
import { remark } from "remark";
import { visit } from "unist-util-visit";
import md5 from "js-md5";
import ReactMarkdownComponent from "../components/ReactMarkdownComponent";
import SourceDialog from "../components/SourceDialog";
import { PartialDocument } from "../api";
import NewGroupDialog from "../components/NewGroupDialog";

type Heading = {
    type: "heading";
    depth: number;
    children: [{ type: "text"; value: string }];
    id: string;
};

export default function MdViewer() {
    const navigate = useNavigate();
    const location = useLocation();
    const { updateTitle } = useTitle();
    const desiredPath = useMemo(
        () => location.pathname.replace("/md/", ""),
        [location]
    );

    const [mdContent, setMdContent] = useState("");
    const [sourceOpen, setSourceOpen] = useState(false);
    const [docTitle, setDocTitle] = useState("");

    const [groupItems, setGroupItems] = useState<PartialDocument[]>([]);
    const [dialogTitle, setDialogTitle] = useState("");
    const [open, setOpen] = useState(false);

    const fetchData = React.useCallback(async () => {
        apiInstance.getDocumentDocumentPathGet(desiredPath).then((res) => {
            console.log(res);
            updateTitle("Reader - " + res.data.title);
            setMdContent(res.data.content);
            setDocTitle(res.data.title);
            setOpen(false);
        });
        // eslint-disable-next-line
    }, [desiredPath]); // 依存性配列にすべての依存性を追加

    useEffect(() => {
        fetchData();
    }, [fetchData]); // 依存性配列にfetchDataを追加

    useEffect(() => {
        if (open === false) {
            setGroupItems([]);
        }
    }, [open]);
    const [toc, setToc] = useState<Heading[]>([]);

    useLayoutEffect(() => {
        if (mdContent) {
            const headings: Heading[] = [];
            remark()
                .use(() => (tree) => {
                    visit(tree, "heading", (node: Heading) => {
                        const text = node.children[0].value;
                        const id = md5(text);
                        headings.push({ ...node, id });
                    });
                    return tree;
                })
                .process(mdContent, (err, file) => {
                    if (err) throw err;
                    setToc(headings);
                    return undefined; // 追加
                });
        }
    }, [mdContent]);

    const handleScrollJump = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <Grid container spacing={2}>
            <Grid
                xs={12}
                md={2}
                item
                id="toc"
                marginLeft={{ xs: 0, md: 2, lg: 10 }}
                sx={{
                    position: { xs: "inherit", md: "sticky" },
                    alignItems: { xs: "inherit", md: "center" },
                }}>
                <Typography variant="h6">目次</Typography>
                {toc.map((heading, index) => (
                    <div key={index}>
                        <Link
                            onClick={() => handleScrollJump(heading.id)}
                            sx={{ cursor: "pointer" }}
                            marginLeft={(heading.depth - 1) * 3}>
                            {heading.children[0].value}
                        </Link>
                    </div>
                ))}
                <hr />
                <Button onClick={() => setSourceOpen(true)}>
                    ソースを見る
                </Button>
                <SourceDialog
                    open={sourceOpen}
                    setOpen={setSourceOpen}
                    source={mdContent}
                    title={`ソース: ${docTitle}`}
                />
            </Grid>
            <Grid xs={12} md={8} item>
                <Backdrop open={open && groupItems.length === 0}>
                    <CircularProgress />
                </Backdrop>
                <NewGroupDialog
                    open={open && groupItems.length !== 0}
                    dialogTitle={dialogTitle}
                    groupItems={groupItems}
                    handleClose={() => {
                        setOpen(false);
                    }}
                />
                <ReactMarkdownComponent
                    mdContent={mdContent}
                    onInternalLinkClicked={(path) => {
                        if (path?.startsWith("group:")) {
                            setOpen(true);
                            apiInstance
                                .getFolderFolderPathGet(
                                    path.replace("group:", "")
                                )
                                .then((res) => {
                                    setGroupItems(res.data.files);
                                    setDialogTitle(res.data.metadata.name);
                                });
                        } else if (path?.startsWith("file:")) {
                            apiInstance
                                .getDocumentDocumentPathGet(
                                    path.replace("file:", "")
                                )
                                .then((res) => {
                                    setMdContent(res.data.content);
                                    setDocTitle(res.data.title);
                                    updateTitle("Reader - " + res.data.title);
                                    setOpen(false);
                                    navigate(
                                        "/md/" + path.replace("file:", "")
                                    );
                                });
                        }
                    }}
                />
            </Grid>
        </Grid>
    );
}
