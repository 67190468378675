import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MdViewer from "./pages/MdViewer";
import NewExplorer from "./pages/NewExplorer";
import MdEditor from "./pages/Editor";
import Uploader from "./pages/Uploader";
import Home from "./pages/Home";

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/md/*" element={<MdViewer />} />
                <Route path="/editor" element={<MdEditor />} />
                <Route path="/upload" element={<Uploader />} />
                <Route path="/explore/*" element={<NewExplorer />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
