import { ThemeProvider } from "@emotion/react";
import { Box, CssBaseline, createTheme } from "@mui/material";
import App from "./App";
import { useTheme } from "./store/ThemeSettingsContext";
import NavBar from "./components/NavBar";
import { AppTitleProvider } from "./store/AppTitleContext";
import { Toaster } from "react-hot-toast";

export default function Wrapper() {
    const { theme: color } = useTheme();

    let theme = createTheme({});
    theme = createTheme({
        palette: {
            mode: color,
            primary: theme.palette.augmentColor({
                color: {
                    main: "#D15552",
                },
                name: "primary",
            }),
        },
    });
    return (
        <AppTitleProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <NavBar />
                <Box margin={1}>
                    <App />
                </Box>
                <Toaster position="bottom-left" />
            </ThemeProvider>
        </AppTitleProvider>
    );
}
