import { styled } from "@mui/material";
import "./Quote.css";
interface Props {
    source?: string;
    children: React.ReactNode;
}

export default function Quote(props: Props) {
    const InnerQuote = styled("blockquote")(({ theme }) => ({
        background: theme.palette.mode === "dark" ? "#1e1e1e" : "#EDEDED",
        color: theme.palette.text.primary,
    }));
    const QuoteSource = styled("span")(({ theme }) => ({
        color: theme.palette.text.secondary,
    }));
    return (
        <InnerQuote>
            {props.children}
            {props.source ? <QuoteSource>{props.source}</QuoteSource> : <></>}
        </InnerQuote>
    );
}
