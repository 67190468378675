import { useMediaQuery } from "@mui/material";
import React, { createContext, useContext, useState } from "react";

type ThemeVariant = "light" | "dark";

export type ThemeSettingsContextType = {
    theme: ThemeVariant;
    updateTheme: (newTheme: ThemeVariant) => void;
};
type Props = {
    children?: React.ReactNode;
};
export const ThemeSettingsContext = createContext<
    ThemeSettingsContextType | undefined
>(undefined);

export const useTheme = () => {
    const context = useContext(ThemeSettingsContext);
    if (!context) {
        throw new Error("useTheme must be used within a ThemeSettingsProvider");
    }
    return context;
};

export const ThemeSettingsProvider: React.FC<Props> = ({ children }) => {
    const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
    const [theme, setTheme] = useState<ThemeVariant>(getDefaultTheme());
    function getDefaultTheme() {
        console.log("get default theme");
        switch (localStorage.getItem("theme")) {
            case "dark":
                return "dark";
            case "light":
                return "light";
            default:
                return prefersDarkMode ? "dark" : "light";
        }
    }
    const updateTheme = (newTheme: ThemeVariant) => {
        setTheme(newTheme);
        localStorage.setItem("theme", newTheme);
        console.log("update theme", theme);
    };

    return (
        <ThemeSettingsContext.Provider value={{ theme, updateTheme }}>
            {children}
        </ThemeSettingsContext.Provider>
    );
};
