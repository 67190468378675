import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "../store/ThemeSettingsContext";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import { useTitle } from "../store/AppTitleContext";

export default function NavBar() {
    const { theme, updateTheme } = useTheme();
    const { title } = useTitle();

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    <IconButton
                        sx={{ ml: 1 }}
                        onClick={() =>
                            theme === "light"
                                ? updateTheme("dark")
                                : updateTheme("light")
                        }
                        color="inherit">
                        {theme === "dark" ? (
                            <Brightness7Icon />
                        ) : (
                            <Brightness4Icon />
                        )}
                    </IconButton>
                </Toolbar>
            </AppBar>
        </Box>
    );
}
