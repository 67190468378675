import ReactMarkdown from "react-markdown";
import { getStaticFile } from "../ApiInstance";
import remarkGfm from "remark-gfm";
import {
    Checkbox,
    Link,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    styled,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
    vscDarkPlus,
    vs,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { useTheme } from "../store/ThemeSettingsContext";
import remarkBreaks from "remark-breaks";
import rehypeAutolinkHeadings from "rehype-autolink-headings";
import Quote from "../components/Quote";
import { visit } from "unist-util-visit";
import md5 from "js-md5";
import { Root, Text } from "hast";
import rehypeRaw from "rehype-raw";
import { Link as RouterLink } from "react-router-dom";

const rehypeAddIdToHeading = () => {
    return (tree: Root) => {
        visit(tree, "element", function (node) {
            if (node.properties) {
                if (!node.properties.id) {
                    const textNode = node.children[0] as Text;
                    if (textNode && textNode.type === "text") {
                        const text = textNode.value;
                        const id = md5(text);
                        node.properties = {
                            ...(node.properties || {}),
                            id,
                        };
                    }
                }
            }
        });
    };
};

interface Props {
    mdContent: string;
    onInternalLinkClicked?: (path?: string) => void;
}

const Code = styled("code")(({ theme }) => ({
    background: theme.palette.secondary.contrastText,
    borderRadius: 8,
    padding: "0.2em 0.4em",
    color: theme.palette.primary.main,
}));

export default function ReactMarkdownComponent(props: Props) {
    const { theme } = useTheme();
    const codeBlockTheme = theme === "dark" ? vscDarkPlus : vs;
    return (
        <ReactMarkdown
            remarkPlugins={[remarkGfm, remarkBreaks]}
            rehypePlugins={
                [rehypeAutolinkHeadings, rehypeAddIdToHeading, rehypeRaw] as any
            }
            children={props.mdContent}
            components={{
                a: (aProps) => {
                    if (aProps.href?.startsWith("/internal:")) {
                        return (
                            <Link
                                onClick={(e) => {
                                    e.preventDefault();
                                    props.onInternalLinkClicked?.(
                                        aProps.href?.replace("/internal:", "")
                                    );
                                }}
                                href="#">
                                {aProps.children}
                            </Link>
                        );
                    } else {
                        return (
                            <Link
                                {...aProps}
                                component={RouterLink}
                                to={aProps.href ?? ""}
                            />
                        );
                    }
                },
                table: ({ children, ...eProps }) => (
                    <TableContainer component={Paper}>
                        <Table {...eProps}>{children}</Table>
                    </TableContainer>
                ),
                thead: ({ children, ...eProps }) => (
                    <TableHead {...eProps}>{children}</TableHead>
                ),
                tbody: ({ children, ...eProps }) => (
                    <TableBody {...eProps}>{children}</TableBody>
                ),
                th: ({ children, ...eProps }) => (
                    <TableCell
                        {...eProps}
                        align={
                            eProps.align === "char" ? undefined : eProps.align
                        }>
                        {children}
                    </TableCell>
                ),
                tr: ({ children, ...eProps }) => (
                    <TableRow {...eProps}>{children}</TableRow>
                ),
                td: ({ children, ...eProps }) => (
                    <TableCell
                        {...eProps}
                        align={
                            eProps.align === "char" ? undefined : eProps.align
                        }>
                        <Typography>{children}</Typography>
                    </TableCell>
                ),
                code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                        <SyntaxHighlighter
                            {...props}
                            children={String(children).replace(/\n$/, "")}
                            style={codeBlockTheme}
                            language={match[1]}
                            PreTag="div"
                            showLineNumbers
                            wrapLines={true}
                        />
                    ) : (
                        <Code {...props} color="">
                            {children}
                        </Code>
                    );
                },
                input: ({ type, ...props }) => {
                    if (type === "checkbox") {
                        return (
                            <Checkbox
                                color="primary"
                                disabled
                                checked={props.checked}></Checkbox>
                        );
                    } else {
                        return <input type={type} {...props} />;
                    }
                },
                img: ({ src, alt, ...props }) => {
                    if (src) {
                        if (src.lastIndexOf("#") !== -1) {
                            const srcWithSize = src.split("#");
                            src = srcWithSize[0];
                            const size = srcWithSize[1];
                            if (size[0] === "h") {
                                props.height = size.slice(1);
                            } else if (size[0] === "b") {
                                props.width = size.slice(1);
                                props.height = size.slice(1);
                            } else {
                                props.width = size;
                            }
                        }
                        if (src.startsWith("hash:")) {
                            src = getStaticFile(src.replace("hash:", ""));
                        }
                    }
                    return <img src={src} alt={alt} {...props} />;
                },
                blockquote: ({ children }) => <Quote>{children}</Quote>,
            }}
            remarkRehypeOptions={{
                footnoteLabel: "脚注",
                allowDangerousHtml: true,
            }}
        />
    );
}
