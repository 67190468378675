import React, { createContext, useContext, useState } from "react";

export type AppTitleContextType = {
    title: string;
    updateTitle: (newTitle: string) => void;
};
type Props = {
    children?: React.ReactNode;
};
export const AppTitleContext = createContext<AppTitleContextType | undefined>(
    undefined
);

export const useTitle = () => {
    const context = useContext(AppTitleContext);
    if (!context) {
        throw new Error("useTitle must be used within a AppTitleProvider");
    }
    return context;
};

export const AppTitleProvider: React.FC<Props> = ({ children }) => {
    const [title, setTitle] = useState<string>("Unknown Title");

    const updateTitle = (newTitle: string) => {
        setTitle(newTitle);
    };

    return (
        <AppTitleContext.Provider value={{ title, updateTitle }}>
            {children}
        </AppTitleContext.Provider>
    );
};
