import { useEffect, useState } from "react";
import ReactMarkdownComponent from "../components/ReactMarkdownComponent";
import MDEditor from "@uiw/react-md-editor";
import { useTheme } from "../store/ThemeSettingsContext";
import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";
import { useTitle } from "../store/AppTitleContext";

const DEFAULT_MARKDOWN = `*Write your markdown to get started!*`;
export default function MdEditor() {
    const location = useLocation();
    const [code, _setCode] = useState<string>(
        location.state?.code ?? localStorage.getItem("code") ?? ""
    );

    const { theme } = useTheme();
    const { updateTitle } = useTitle();
    function setCode(code: string) {
        localStorage.setItem("code", code);
        _setCode(code);
    }

    useEffect(() => {
        updateTitle("Markdown Editor");
    }, [updateTitle]);
    return (
        <div data-color-mode={theme}>
            <Box margin={2}>
                <Typography>
                    コンテンツのアップロードは
                    <Link
                        component={RouterLink}
                        to="/upload"
                        target="_blank"
                        rel="noopener noreferrer">
                        こちら
                    </Link>
                    からどうぞ。
                </Typography>
                <Typography>
                    注意: これは<b>Markdownエディター</b>であり、{" "}
                    <b>ファイルエディター</b>では<u>ありません</u>
                    。編集はローカルに保存され、どこにもアップロードされません。
                    <br />
                    ファイルを公開するには、バックエンドサーバーに手動で配置する必要があります。
                </Typography>
            </Box>

            <MDEditor
                value={code}
                onChange={(code) => {
                    setCode(code ?? "");
                }}
                placeholder={DEFAULT_MARKDOWN}
                components={{
                    preview: (code) => (
                        <ReactMarkdownComponent
                            mdContent={code ? code : DEFAULT_MARKDOWN}
                        />
                    ),
                }}
                height={600}
            />
        </div>
    );
}
