/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { getStaticFile } from "../ApiInstance";

export interface NewGroupItemProps {
    groupLogoHash: string;
    groupImgSize: number;
    groupName: string;
    groupPath: string;
    onClick: (event: React.MouseEvent) => void;
}
export default function NewGroupItem(props: NewGroupItemProps) {
    return (
        <>
            <div
                onClick={props.onClick}
                css={css`
                    margin-bottom: 30px;
                    padding: 30px 15px;
                `}>
                <div>
                    <img
                        src={getStaticFile(props.groupLogoHash)}
                        alt={props.groupName}
                        width={props.groupImgSize}
                        height={props.groupImgSize}
                    />
                </div>
                <div
                    css={css`
                        font-size: 1.4rem;
                        color: #d15552;
                        text-align: center;
                    `}>
                    <span>{props.groupName}</span>
                </div>
            </div>
        </>
    );
}
