import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
    const navigate = useNavigate();
    useEffect(() => {
        navigate("/explore/");
    }, [navigate]);

    return (
        // <Stack
        //     direction={"row"}
        //     spacing={2}
        //     justifyContent={"center"}
        //     alignItems={"center"}>
        //     <CardItem
        //         path={"/file/"}
        //         chip={<CustomChip icon={<ExploreIcon />} />}
        //         title={"エクスプローラー"}
        //         description={"Markdownファイルを探索・閲覧します。"}
        //         width={500}></CardItem>
        //     <CardItem
        //         path={"/editor"}
        //         chip={<CustomChip icon={<EditNoteIcon />} />}
        //         title={"Markdown エディター"}
        //         description={"Markdownの執筆・プレビューに最適です。"}
        //         width={500}></CardItem>
        //     <CardItem
        //         path={"/upload"}
        //         chip={<CustomChip icon={<CloudUploadIcon />} />}
        //         title={"アップローダー"}
        //         description={
        //             "10MBまでの画像ファイルをアップロードします。キーが必要です。"
        //         }
        //         width={500}></CardItem>
        // </Stack>
        <></>
    );
}
