import {
    Button,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { apiInstance, getStaticFile } from "../ApiInstance";
import { UploadFileResponse } from "../api";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import toast from "react-hot-toast";

export default function DragAndDropComponent() {
    const [droppedFiles, setDroppedFiles] = useState<File[]>([]);
    const [successFiles, setSuccessFiles] = useState<UploadFileResponse[]>([]);
    const [failedFiles, setFailedFiles] = useState<string[]>([]);
    const [uploadKey, setUploadKey] = useState(
        localStorage.getItem("uploadKey") ?? ""
    );

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        const newFiles = [...e.dataTransfer.files];
        setDroppedFiles([...droppedFiles, ...newFiles]);
        const toastId = toast.loading("アップロード中…");
        localStorage.setItem("uploadKey", uploadKey);
        apiInstance
            .uploadFileUploadPost(newFiles, {
                headers: { Authorization: "Bearer " + uploadKey },
            })
            .then((res) => {
                toast.dismiss(toastId);
                setDroppedFiles((prev) =>
                    prev.filter((file) => !newFiles.includes(file))
                );
                res.data.accepted.forEach((item) =>
                    toast.success(`アップロード完了: ${item.filename}`)
                );
                setSuccessFiles([...successFiles, ...res.data.accepted]);
                res.data.rejected.forEach((item) =>
                    toast.error(`アップロード失敗: ${item}`)
                );
                setFailedFiles([...failedFiles, ...res.data.rejected]);
            })
            .catch((err) => {
                toast.error("アップロードが拒否されました。");
            })
            .finally(() => toast.dismiss(toastId));
    };

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
    };

    const reset = () => {
        setDroppedFiles([]);
        setSuccessFiles([]);
        setFailedFiles([]);
    };

    console.log("rendered");
    return (
        <div onDrop={handleDrop} onDragOver={handleDragOver}>
            <TextField
                label="Upload Key"
                value={uploadKey}
                onChange={(e) => setUploadKey(e.target.value)}
                margin={"dense"}
            />
            <br />
            <Button variant="outlined" color="secondary" onClick={reset}>
                Reset
            </Button>
            <Typography>ここにファイルをドロップしてください。</Typography>

            <ImageList sx={{ height: "60vh" }} cols={5} rowHeight={200}>
                {successFiles.map((item) => (
                    <ImageListItem key={item.hash}>
                        <img
                            src={getStaticFile(item.hash)}
                            alt={item.filename}
                            style={{
                                objectFit: "contain",
                                height: 200,
                            }}
                        />
                        <ImageListItemBar
                            title={item.filename}
                            subtitle={item.hash}
                            actionIcon={
                                // Copy hash
                                <Tooltip title="Markdownとしてコピー">
                                    <IconButton
                                        sx={{
                                            color: "rgba(255, 255, 255, 0.54)",
                                        }}
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                `![${item.filename}](hash:${item.hash})`
                                            );
                                            toast.success(
                                                "Markdownをコピーしました！"
                                            );
                                        }}>
                                        <LinkIcon />
                                    </IconButton>
                                </Tooltip>
                            }
                        />
                    </ImageListItem>
                ))}
            </ImageList>
        </div>
    );
}
