import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    useMediaQuery,
    useTheme as useMuiTheme,
} from "@mui/material";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import {
    vscDarkPlus,
    vs,
} from "react-syntax-highlighter/dist/esm/styles/prism";
import { useNavigate } from "react-router-dom";
import { useTheme } from "../store/ThemeSettingsContext";

interface Props {
    source: string;
    language?: string;
    open: boolean;
    setOpen: (open: boolean) => void;
    title: string;
}

export default function SourceDialog(props: Props) {
    const { theme } = useTheme();
    const muiTheme = useMuiTheme();
    const navigate = useNavigate();
    const codeBlockTheme = theme === "dark" ? vscDarkPlus : vs;
    const handleClose = () => props.setOpen(false);
    const miniWidth = useMediaQuery(muiTheme.breakpoints.down("lg"));
    const miniHeight = useMediaQuery("@media (max-height: 800px)");
    return (
        <Dialog
            maxWidth={false}
            open={props.open}
            onClose={handleClose}
            fullScreen={miniWidth || miniHeight}>
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <SyntaxHighlighter
                    children={props.source}
                    style={codeBlockTheme}
                    language={props.language ?? "markdown"}
                    PreTag="div"
                    showLineNumbers
                    wrapLines={true}
                    customStyle={{
                        maxHeight: "70vh",
                        overflowY: "auto",
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        navigate("/editor", { state: { code: props.source } });
                    }}>
                    エディターで見る
                </Button>
                <Button onClick={handleClose}>閉じる</Button>
            </DialogActions>
        </Dialog>
    );
}
