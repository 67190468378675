import {
    Dialog,
    DialogTitle,
    DialogContent,
    List,
    ListItemButton,
    ListItemText,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PartialDocument } from "../api";

interface Props {
    groupItems: PartialDocument[];
    open: boolean;
    handleClose: () => void;
    dialogTitle: string;
}
export default function NewGroupDialog(props: Props) {
    const navigate = useNavigate();

    return (
        <Dialog open={props.open} onClose={props.handleClose}>
            <DialogTitle>{props.dialogTitle}</DialogTitle>
            <DialogContent>
                <List>
                    {props.groupItems.map((v) => (
                        <ListItemButton
                            key={v.path}
                            onClick={() => navigate("/md/" + v.path)}>
                            <ListItemText>{v.title}</ListItemText>
                        </ListItemButton>
                    ))}
                </List>
            </DialogContent>
        </Dialog>
    );
}
