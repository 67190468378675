import axios from "axios";
import { DefaultApi } from "./api/api";

export const API_ENDPOINT =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
        ? "http://localhost:8000"
        : "https://documents-api.shieru-lab.com";
console.log(API_ENDPOINT);
export const axiosInstance = axios.create({ baseURL: API_ENDPOINT });
export const apiInstance = new DefaultApi(
    undefined,
    API_ENDPOINT,
    axiosInstance
);
export function getStaticFile(hash: string) {
    return API_ENDPOINT + "/static/" + hash;
}
