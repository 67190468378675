/* tslint:disable */
/* eslint-disable */
/**
 * FastAPI
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    'parent': string;
}
/**
 * 
 * @export
 * @interface DocumentGroup
 */
export interface DocumentGroup {
    /**
     * 
     * @type {string}
     * @memberof DocumentGroup
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGroup
     */
    'parent'?: string;
    /**
     * 
     * @type {DocumentGroupMetadata}
     * @memberof DocumentGroup
     */
    'metadata': DocumentGroupMetadata;
    /**
     * 
     * @type {Array<PartialDocument>}
     * @memberof DocumentGroup
     */
    'files': Array<PartialDocument>;
    /**
     * 
     * @type {Array<PartialDocumentGroup>}
     * @memberof DocumentGroup
     */
    'folders': Array<PartialDocumentGroup>;
}
/**
 * 
 * @export
 * @interface DocumentGroupMetadata
 */
export interface DocumentGroupMetadata {
    /**
     * 
     * @type {string}
     * @memberof DocumentGroupMetadata
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGroupMetadata
     */
    'description': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentGroupMetadata
     */
    'icon': string;
    /**
     * 
     * @type {Array<DocumentOverride>}
     * @memberof DocumentGroupMetadata
     */
    'override': Array<DocumentOverride>;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentGroupMetadata
     */
    'unlisted'?: boolean;
}
/**
 * 
 * @export
 * @interface DocumentOverride
 */
export interface DocumentOverride {
    /**
     * 
     * @type {string}
     * @memberof DocumentOverride
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof DocumentOverride
     */
    'title'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DocumentOverride
     */
    'unlisted'?: boolean;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface PartialDocument
 */
export interface PartialDocument {
    /**
     * 
     * @type {string}
     * @memberof PartialDocument
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof PartialDocument
     */
    'parent'?: string;
    /**
     * 
     * @type {string}
     * @memberof PartialDocument
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof PartialDocument
     */
    'icon': string;
    /**
     * 
     * @type {DocumentOverride}
     * @memberof PartialDocument
     */
    'override'?: DocumentOverride;
}
/**
 * 
 * @export
 * @interface PartialDocumentGroup
 */
export interface PartialDocumentGroup {
    /**
     * 
     * @type {string}
     * @memberof PartialDocumentGroup
     */
    'path': string;
    /**
     * 
     * @type {string}
     * @memberof PartialDocumentGroup
     */
    'parent'?: string;
    /**
     * 
     * @type {DocumentGroupMetadata}
     * @memberof PartialDocumentGroup
     */
    'metadata': DocumentGroupMetadata;
}
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'filename': string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'hash': string;
}
/**
 * 
 * @export
 * @interface UploadResult
 */
export interface UploadResult {
    /**
     * 
     * @type {Array<UploadFileResponse>}
     * @memberof UploadResult
     */
    'accepted': Array<UploadFileResponse>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadResult
     */
    'rejected': Array<string>;
}
/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * ApiV1Api - axios parameter creator
 * @export
 */
export const ApiV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Api V1 Main Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MainRootApiV1Get: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApiV1Api - functional programming interface
 * @export
 */
export const ApiV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ApiV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Api V1 Main Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1MainRootApiV1Get(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1MainRootApiV1Get(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ApiV1Api - factory interface
 * @export
 */
export const ApiV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ApiV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Api V1 Main Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1MainRootApiV1Get(options?: any): AxiosPromise<void> {
            return localVarFp.apiV1MainRootApiV1Get(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ApiV1Api - object-oriented interface
 * @export
 * @class ApiV1Api
 * @extends {BaseAPI}
 */
export class ApiV1Api extends BaseAPI {
    /**
     * 
     * @summary Api V1 Main Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApiV1Api
     */
    public apiV1MainRootApiV1Get(options?: AxiosRequestConfig) {
        return ApiV1ApiFp(this.configuration).apiV1MainRootApiV1Get(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Document
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDocumentPathGet: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getDocumentDocumentPathGet', 'path', path)
            const localVarPath = `/document/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get File
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileFileHashGet: async (hash: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'hash' is not null or undefined
            assertParamExists('getFileFileHashGet', 'hash', hash)
            const localVarPath = `/file/{hash}`
                .replace(`{${"hash"}}`, encodeURIComponent(String(hash)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Folder
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderFolderPathGet: async (path: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'path' is not null or undefined
            assertParamExists('getFolderFolderPathGet', 'path', path)
            const localVarPath = `/folder/{path}`
                .replace(`{${"path"}}`, encodeURIComponent(String(path)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload File
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileUploadPost: async (files: Array<File>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'files' is not null or undefined
            assertParamExists('uploadFileUploadPost', 'files', files)
            const localVarPath = `/upload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication HTTPBearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (files) {
                files.forEach((element) => {
                    localVarFormParams.append('files', element as any);
                })
            }

    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Document
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentDocumentPathGet(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Document>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentDocumentPathGet(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get File
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileFileHashGet(hash: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileFileHashGet(hash, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Folder
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFolderFolderPathGet(path: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocumentGroup>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFolderFolderPathGet(path, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async rootGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.rootGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Upload File
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileUploadPost(files: Array<File>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileUploadPost(files, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Document
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentDocumentPathGet(path: string, options?: any): AxiosPromise<Document> {
            return localVarFp.getDocumentDocumentPathGet(path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get File
         * @param {string} hash 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileFileHashGet(hash: string, options?: any): AxiosPromise<void> {
            return localVarFp.getFileFileHashGet(hash, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Folder
         * @param {string} path 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderFolderPathGet(path: string, options?: any): AxiosPromise<DocumentGroup> {
            return localVarFp.getFolderFolderPathGet(path, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Root
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rootGet(options?: any): AxiosPromise<void> {
            return localVarFp.rootGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload File
         * @param {Array<File>} files 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileUploadPost(files: Array<File>, options?: any): AxiosPromise<UploadResult> {
            return localVarFp.uploadFileUploadPost(files, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Get Document
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getDocumentDocumentPathGet(path: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getDocumentDocumentPathGet(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get File
     * @param {string} hash 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFileFileHashGet(hash: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFileFileHashGet(hash, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Folder
     * @param {string} path 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFolderFolderPathGet(path: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getFolderFolderPathGet(path, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Root
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public rootGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).rootGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload File
     * @param {Array<File>} files 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadFileUploadPost(files: Array<File>, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadFileUploadPost(files, options).then((request) => request(this.axios, this.basePath));
    }
}



