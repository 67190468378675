/** @jsxImportSource @emotion/react */

import NewGroupItem from "../components/NewGroupItem";
import { apiInstance } from "../ApiInstance";
import { useNavigate } from "react-router-dom";
import { css } from "@emotion/react";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { PartialDocument } from "../api";
import { Backdrop, CircularProgress } from "@mui/material";
import NewGroupDialog from "../components/NewGroupDialog";
import { useTitle } from "../store/AppTitleContext";

const items = [
    {
        groupImgSize: 70,
        groupLogoHash:
            "49ebf33299a92c95e957a2a7fd348fb10fdefe2560dd1e5d2590075418012312",
        groupName: "手足の傷",
        groupPath: "2-Aid/1.5.6.md",
        toDirect: true,
    },
    {
        groupImgSize: 70,
        groupLogoHash:
            "c810b2c57995843ae19465e41a72bb3251d7f4771309ed69db6b9da54bdd8fac",
        groupName: "病院一覧",
        groupPath: "hospital.md",
        toDirect: true,
    },
    {
        groupImgSize: 70,
        groupLogoHash:
            "7d6b1801cbf5554595481e1ba5be7c5e0eac827ee1f0c3ef5f8918da3bf2fd1d",
        groupName: "ヒヤリハット",
        groupPath: "incident.md",
        toDirect: true,
    },
    {
        groupImgSize: 50,
        groupLogoHash:
            "01d614c64fca1ca9c183cf3bac4f1f307b94e337e54d1210a7ff7ea8d2d5e7dd",
        groupName: "応急処置",
        groupPath: "2-Aid",
    },
    {
        groupImgSize: 50,
        groupLogoHash:
            "d891f11d85cc1920ceac1c54fbbbef0376d5346720f3c8590f72f779a264579b",
        groupName: "打撲",
        groupPath: "1-Bruise",
    },
    {
        groupImgSize: 50,
        groupLogoHash:
            "3a6d18fb6007e8678c69b3a0e7a60e0d7dece926b7a58329355417cbdd1c70d5",
        groupName: "緊急時対応",
        groupPath: "0-Emergency",
    },
    {
        groupImgSize: 50,
        groupLogoHash:
            "fdb0c336b5d48ae8772e198d26252b9919fb9d0bb1b0b9b677cd9bc9e80cb396",
        groupName: "水難事故",
        groupPath: "4-Aquatic",
    },
    {
        groupImgSize: 50,
        groupLogoHash:
            "b95f7db6ded293808ae449d135b1d9f57e424063d03ae819ec2dceacd97620ee",
        groupName: "雪の対応",
        groupPath: "snow.md",
        toDirect: true,
    },
    {
        groupImgSize: 50,
        groupLogoHash:
            "bbcfdd6326f480485d7acefe62de4700aad7481e41640cbc0e73a8ed0c56e9fc",
        groupName: "飲食物など",
        groupPath: "3-Food",
    },
    {
        groupImgSize: 50,
        groupLogoHash:
            "2443bc55edceb6540bc32b49bc8a1d23d60949f18f86bca5764640861ec0ee0d",
        groupName: "その他",
        groupPath: "5-Resources",
    },
];

export default function NewExplorer() {
    const navigate = useNavigate();
    const [groupItems, setGroupItems] = useState<PartialDocument[]>([]);
    const [dialogTitle, setDialogTitle] = useState("");
    const [open, setOpen] = useState(false);
    const { updateTitle } = useTitle();

    useEffect(() => {
        if (open === false) {
            setGroupItems([]);
        }
    }, [open]);

    useEffect(() => {
        updateTitle("さかっこクラブ 事故対応マニュアル");
    });
    return (
        <Box
            css={css`
                display: flex;
                flex-wrap: wrap;
                justify-content: space-around;
                font-size: 62.5%;
                width: 100%;
                box-sizing: border-box;
                font-weight: 500;
                font-size: 1.6em;
                line-height: 2.4rem;
                text-align: center;
            `}>
            <NewGroupDialog
                dialogTitle={dialogTitle}
                groupItems={groupItems}
                handleClose={() => setOpen(false)}
                open={open && groupItems.length !== 0}
            />
            <Backdrop open={open && groupItems.length === 0}>
                <CircularProgress />
            </Backdrop>
            {items.map((v) => (
                <NewGroupItem
                    {...v}
                    onClick={() => {
                        console.log("Clicked", v);
                        if (v.toDirect) {
                            navigate("/md/" + v.groupPath);
                            return;
                        }
                        setOpen(true);
                        apiInstance
                            .getFolderFolderPathGet(v.groupPath)
                            .then((res) => {
                                setDialogTitle(v.groupName);
                                setGroupItems(res.data.files);
                            });
                    }}></NewGroupItem>
            ))}
        </Box>
    );
}
